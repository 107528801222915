
import './style.scss';
import Register from "./register/index.jsx"
import Subdomains from "./subdomains/index.jsx"
import Details from "./details/index.jsx"

let data = {
  Register,
  Details,
  Subdomains
}

export default data;
