import React, {useState,Fragment } from 'react'
import { Link } from 'react-router-dom'

import tronWeb from "../../tronweb"
import {Models} from '../../components'
import {Utils,NameHash,PopUp} from '../../helper'
import {ReactComponent as CopyIcon} from '../../images/copy.svg';
import {ReactComponent as EditIcon} from '../../images/edit.svg';


function DetailsBody(props){
  const [copied , setCopied] = useState(false);

  let {params,tab,tld} = props?.data??{}
  let name = params?.domain??"";

  const handleCopy = () =>{
   let textField = document.createElement('textarea')
   textField.innerText = name;
   document.body.appendChild(textField)
   textField.select()
   document.execCommand('copy')
   textField.remove();

   setCopied(true)
   setTimeout(function() {
     setCopied(false)
   }, 3000);
  }


  return(
    <div className="main">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-12">
            <Models.SearchInput
              data = {{label:name,tld,fullview:true}}
              />
          </div>
          <div className="col-12">
            <div className = "details__body">
              <div className = "details__body__header">
                <div className = "details__body__header__name">
                  <p>{name}</p>
                  {!copied && <button onClick={handleCopy}><CopyIcon/></button>}
                  {!!copied && <span>Copied</span>}
                </div>

                <div className="details__body__header__tabs">
                  <Link to={`/name/${name}/register`} className={tab === "register"?"active":""}>Register</Link>
                  <Link to={`/name/${name}/details`} className={tab === "details"?"active":""}>Details</Link>
                  <Link to={`/name/${name}/subdomains`} className={tab === "subdomains"?"active":""}>SubDomains</Link>
                </div>
              </div>

              <div className = "details__body__info">
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const AddressView = (props) =>{
  const [copied , setCopied] = useState(false);
  let {address,anchor,hideBtn} = props?.data??"";
  let isAnchor = !!address && !!anchor;
  let showBtn = !hideBtn && !!address;

  if(address === "Not set"){
    isAnchor = showBtn = false;
  }else if(!!address && !!Utils.isZeroAddress(address)){
    address = "Null Address"
  }

  const handleCopy = () =>{
    if(!address) return;
   let textField = document.createElement('textarea')
   textField.innerText = address;
   document.body.appendChild(textField)
   textField.select()
   document.execCommand('copy')
   textField.remove();

   setCopied(true)
   setTimeout(function() {
     setCopied(false)
   }, 3000);
  }
  return(
    <div className="address__view">
      {!isAnchor && <div className="address__view__text">{address || "--"}</div>}
      {!!isAnchor && <Link className="address__view__text" to={`/search/${address}`}>{address || "--"}</Link>}
      {!copied && showBtn && <div className="address__view__copy" onClick={handleCopy}><CopyIcon/></div>}
      {!!copied && showBtn && <div className="address__view__copied">Copied</div>}
    </div>
  )
}




const RecordAddrUpdate = (props) =>{
  let{key,value,record,address} = props?.data??{};
  let isController = !!address && address === record.controller;
  let {name,chainId} = Utils.chainData(key);

  const [newValue , setValue] = useState(value || "");
  const [isPopUp , setPopUp] = useState(false);
  const [isProcess , setProcess] = useState(false);

  const handleSubmit = () =>{
    try {
      setValue(newValue.split(" ").join(""));
      if(value === newValue) return Utils.setToastAlert(`Please enter new ${name} address`,"warning");
      NameHash.addressDecoder(chainId,newValue);
      handleUpload()
    } catch (e) {
      // console.log(e);
      Utils.setToastAlert("Please enter valid address","warning");
    }
  }

  const handleUpload = async() =>{
    try {
      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      setProcess(true);
      let _value = NameHash.addressDecoder(chainId,newValue);
      let node = NameHash.nameToNode(record.name);
      let instance = await window.tronWeb.contract().at(Utils.resolver);
      await instance.methods["setAddr(bytes32,uint256,bytes)"](node,chainId,_value).send({
       feeLimit:20 * 1e6,
      });
      Utils.setToastAlert(`${name} address update request submitted successfully`,"success")
      setPopUp(false)
      setProcess(false);
      props.handler()
    } catch (e) {
      setProcess(false);
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert(`Failed to update ${name} address`,"error")
      }
    }
  }
  return(
    <Fragment>
      {!!isController && <button
        className="domain__record___edit"
        onClick={()=>setPopUp(true)}
        disabled={!!isPopUp || !!isProcess}
        >
        <EditIcon/>
      </button>}

      {!!isPopUp && !!isController && <PopUp.Modal
        className="modal__sm"
        title={`Update ${key} Address`}
        onClose={()=>setPopUp(false)}
        >
        <div className="record__popup__form">
          <p>Set the {name} blockchain address associated with this record. Any {key} Transaction will receive at this address, if set.</p>
          <input
            className="record__popup__form__input"
            type="text"
            placeholder={`Enter ${key} address`}
            value={newValue}
            onChange={(e)=>setValue(e.target.value)}
            />
          <button
            onClick={handleSubmit}
            >
            Update
            {!!isProcess && <div className="ball__pulse">
              <div></div>
              <div></div>
              <div></div>
            </div>}
          </button>
        </div>
      </PopUp.Modal>}
    </Fragment>
  )
}



const RecordTextUpdate = (props) =>{
  let{key,value,record,address} = props?.data??{};
  let isController = !!address && address === record.controller;

  let {name,type,length,prefix} = Utils.textData(key);
  let isInputField = type !== "text" && type !== "largetext"
  const [newValue , setValue] = useState(value || prefix);
  const [isPopUp , setPopUp] = useState(false);
  const [isProcess , setProcess] = useState(false);

  const handleInput = (e) =>{
    let _value = e.target.value;
    if(_value.length > 0){
      let _initial = _value.substring(0,prefix.length).toLowerCase();
      if (!prefix.includes(_initial)) {
        if (_value.length <= prefix.length) {
          _value = newValue;
        }else {
          _value = prefix;
        }
      }else {
        let _secondtInitial = _value.substring(prefix.length,prefix.length*2).toLowerCase();
        if(_secondtInitial === prefix){
          _value = _value.substring(prefix.length);
        }
      }
    }
    setValue(_value)


  }
  const handleSubmit = async() =>{
    try {
      setValue(newValue.trim());

      if(newValue.length > length) return Utils.setToastAlert(`Maximum length for ${name} exceeded`,"warning");
      if(value === newValue) return Utils.setToastAlert(`Please enter new ${name}`,"warning");
      if(isProcess) return Utils.setToastAlert("Previous request processing","info");

      setProcess(true);
      let node = NameHash.nameToNode(record.name);
      let instance = await window.tronWeb.contract().at(Utils.resolver);
      let _value = newValue.substring(prefix.length);
      await instance.methods["setText(bytes32,string,string)"](node,key,_value).send({
       feeLimit:20 * 1e6,
      });
      Utils.setToastAlert(`${name} update request submitted successfully`,"success")
      setPopUp(false)
      setProcess(false);
      props.handler()
    } catch (e) {
      setProcess(false);
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert(`Failed to update ${name}`,"error")
      }
    }
  }
  return(
    <Fragment>
      {!!isController && <button
        className="domain__record___edit"
        onClick={()=>setPopUp(true)}
        disabled={!!isPopUp || !!isProcess}
        >
        <EditIcon/>
      </button>}

      {!!isPopUp && !!isController && <PopUp.Modal
        className="modal__sm"
        title={`Update ${name}`}
        onClose={()=>setPopUp(false)}
        >
        <div className="record__popup__form">
          <p>Set the text record of {name} associated with this record.</p>
          {!!isInputField && <input
            className="record__popup__form__input"
            type={type === "input"?"text":"number"}
            placeholder={`Enter ${name}`}
            value={newValue}
            onChange={handleInput}
            />}
          {!isInputField && <textarea
            className="record__popup__form__input"
            placeholder={`Enter ${name}`}
            value={newValue}
            onChange={handleInput}
            />}
          <button
            onClick={handleSubmit}
            >
            Update
            {!!isProcess && <div className="ball__pulse">
              <div></div>
              <div></div>
              <div></div>
            </div>}
          </button>
        </div>
      </PopUp.Modal>}
    </Fragment>
  )
}

export{
  DetailsBody,
  AddressView,
  RecordAddrUpdate,
  RecordTextUpdate,
}
