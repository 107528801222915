/***********************************|
|         Toast alerts              |
|__________________________________*/
// let toastAlert = [];
let toastCb;
const setToastAlert = (content,appearance="warning")=>{
  if (content && toastCb) {
    // toastAlert.push({appearance,content})
    toastCb(null,{appearance,content})
  }
}
const getToastAlert = (callback)=>{
  toastCb = callback;
  // setInterval(function () {
  //   if (toastAlert.length) {
  //     let _content = toastAlert;
  //     toastAlert = []
  //     callback(null,_content)
  //   }
  // }, 500);
}

/******************************************************************/
/***********************************|
|       live user provider          |
|__________________________________*/
let user = {
  address:false,
  name:"",
}
const viewUser = (val = "address")=>{
  return user[val];
}

const setUser = (address = "", name = "")=>{
  user = {address,name}
}

const getUser =(callback)=>{
  callback(null,user)
  let _address = user.address;
  setInterval(function () {
    if (_address !== user.address) {
      _address = user.address;
      callback(null,user)
    }
  }, 2000);
}

/******************************************************************/

// asynchronous waiting for async/await
const wait = async(time = 10000) =>await new Promise((resolve)=>setTimeout(resolve, time));




const shortAddress = (owner,_short=false)=>{
  if(!owner || owner === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb") return "Null Address"
  if(owner.length !== 34) return owner;
  if(window.innerWidth < 768 || !!_short){
    return owner.substring(0, 2)+"..."+owner.substring(owner.length - 5, owner.length);
  }
  return owner.substring(0, 3)+"..."+owner.substring(owner.length - 6, owner.length);
}

const shortName = (name,_short=false)=>{
  if(!name) return ""
  if(name.length < 15) return name;
  let labels = name.split(".");
  let _tld = labels[labels.length - 1];
  if(window.innerWidth < 768 || !!_short){
    return labels[0].substring(0, 6)+"..."+_tld;
  }
  return labels[0].substring(0, 9)+"..."+_tld;
}

/******************************************************************/

/***********************************|
|        Number sanitizer           |
|__________________________________*/
const floor = (val,decimal = 6)=>{
  val = parseFloat(val);
 if(isNaN(val) || val < 0) return 0;
  decimal = Math.floor(decimal)
 if(isNaN(decimal)){
   decimal = 6;
 }
return Math.floor(val * (10 ** decimal)) / (10 ** decimal);
}
const ceil = (val,decimal = 6)=>{
  val = parseFloat(val);
 if(isNaN(val) || val < 0) return 0;
  decimal = Math.floor(decimal)
 if(isNaN(decimal)){
   decimal = 6;
 }
return Math.ceil(val * (10 ** decimal)) / (10 ** decimal);
}

const largepretty = (val,fraction = 2) => {
  val = parseFloat(val);
   if(isNaN(val) || val < 0 || !val)return 0;
   let divider = val >= 1e9?9:val >= 1e6?6:val >= 1e4?3:0;
   let name = val >= 1e9?'B':val >= 1e6?"M":val >= 1e4?"K":'';

   val /= 10 ** divider;
   val = Math.ceil(val * 100) / (100);
  return val.toLocaleString(undefined, { maximumFractionDigits: fraction })+name;
}

const pretty = (function(){
  function calculate(val, decimal = 6){
    val = parseFloat(val);
   if(isNaN(val) || val <= 0){
     val = 0;
   }
    decimal = Math.floor(decimal)
   if(isNaN(decimal)){
     decimal = 6;
   }
   return[val,decimal]
  }
  function main(a, b) {
    let [v,d] = calculate(a, b)
    d = v > 1e8?Math.min(1,d):v > 1e7?Math.min(2,d):d;
    v = Math.ceil(v * (10 ** d)) / (10 ** d);
   return v.toLocaleString(undefined, { minimumFractionDigits: d });
  }
  function floor(a, b) {
    let [v,d] = calculate(a, b)
    v = Math.floor(v * (10 ** d)) / (10 ** d);
   return v.toLocaleString(undefined, { minimumFractionDigits: d });
  }
  function ceil(a, b) {
    let [v,d] = calculate(a, b)
    v = Math.ceil(v * (10 ** d)) / (10 ** d);
   return v.toLocaleString(undefined, { minimumFractionDigits: d });
  }
  function real(v, d) {
   return v.toLocaleString(undefined, { maximumFractionDigits: d });
  }
  function unsafe(v, d = 6) {
    v = Math.ceil(v * (10 ** d)) / (10 ** d);
   return v.toLocaleString(undefined, { maximumFractionDigits: d });
  }

  main.floor= floor;
  main.ceil= ceil;
  main.real= real;
  main.unsafe= unsafe;


  return main;
})();
/******************************************************************/

/***********************************|
|   Time offset and viewable        |
|__________________________________*/

const getTimeInGMT = (timestamp)=>{
  let _date = new Date(timestamp)
  let _offset  = _date.getTimezoneOffset(), _o = Math.abs(_offset);;
  return `${_date.toLocaleString()} (UTC${_offset <= 0?"+":"-"}${("00" + Math.floor(_o / 60)).slice(-2)}:${("00" + (_o % 60)).slice(-2)})`;
}


/******************************************************************/

/***********************************|
|        String validator           |
|__________________________________*/
const validateName = (name,min=3,max=21) =>{
  if(!name) return true;
  let regex = new RegExp(/^[a-zA-Z][a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/);
  if(name.length >= min && name.length <= max && regex.test(name)) return true;
  return false;
}
const validateUserame = (name,min=3,max=21) =>{
  let regex = new RegExp(/^(?=[a-zA-Z0-9])+(?!.*[_.]$)(?!.*?[._]{2})[a-zA-Z0-9_.]+$/);
  if(name.length >= min && name.length <= max && regex.test(name)) return true;
  return false;
}
const validateDescription = (name,min=0,max=250) =>{
  //(?!.*[,-_\s]$) => not allowed at end
  // (?!.*[,-_]{2}) => not allow consecutive pattern
  // if(!min && !name.length) return true;
  // let regex = new RegExp(/^[a-zA-Z]+(?!.*[,-_\s]$)(?!.*[,-_]{2})(?!.*[-_\s]{2})([ A-Za-z0-9,.-_]+)*$/);
  // if(name.length >= min && name.length <= max && regex.test(name)) return true;
  if(name.length >= min && name.length <= max) return true;

  return false;
}

const validateSocial = (value,min=5,max=20) =>{
  if(value.length >= min && value.length <= max) return true;
  return false;
}



const isZeroAddress = (address) =>{
  return !address ||
  address === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" ||
  address === "410000000000000000000000000000000000000000" ||
  address === "0x0000000000000000000000000000000000000000";
}
/******************************************************************/

/***********************************|
|        Public Resolver            |
|__________________________________*/

const blockchains = [{symbol:"TRX",name:"TRON",chainId:195},
{symbol:"BTC",name:"Bitcoin",chainId:0},
{symbol:"ETH",name:"Ethereum",chainId:60},
{symbol:"BSC",name:"Binance Smart Chain",chainId:9006},
{symbol:"LTC",name:"Litecoin",chainId:2},
{symbol:"DOGE",name:"Dogecoin",chainId:3},
{symbol:"FTM",name:"Fantom",chainId:1007},
{symbol:"MATIC",name:"Polygon",chainId:966},
{symbol:"ETC",name:"Ethereum Classic",chainId:61},
{symbol:"XRP",name:"Ripple",chainId:144}]

const chainIds = () => blockchains.map(i=>i.chainId);
const chainSymbol = () => blockchains.map(i=>i.symbol);

const chainData = (symbol = "TRX") => blockchains.find(i=>i.symbol === symbol) || blockchains[0];
const chainId = (symbol = "TRX") => blockchains.find(i=>i.symbol === symbol).chainId || 195;
const chainName = (symbol = "TRX") => blockchains.find(i=>i.symbol === symbol).name || "TRON";



const texts = [
  {key:"email",name:"email address",prefix:"",type:"input",length:512,anchor:false},
  {key:"url",name:"website url",prefix:"https://",type:"text",length:256,anchor:true},
  {key:"avatar",name:"avatar url",prefix:"https://",type:"text",length:512,anchor:true},
  {key:"description",name:"description",prefix:"",type:"largetext",length:300,anchor:false},
  {key:"notice",name:"notice",prefix:"",type:"largetext",length:200,anchor:false},
  {key:"keywords",name:"keywords",prefix:"",type:"text",length:512,anchor:false},
  {key:"location",name:"location",prefix:"",type:"text",length:512,anchor:false},
  {key:"phone",name:"phone number",prefix:"",type:"number",length:100,anchor:false},
  {key:"discord",name:"discord account",prefix:"https://discord.com/",type:"input",length:256,anchor:true},
  {key:"github",name:"github account",prefix:"https://github.com/",type:"input",length:256,anchor:true},
  {key:"reddit",name:"reddit account",prefix:"https://www.reddit.com/r/",type:"input",length:256,anchor:true},
  {key:"twitter",name:"twitter account",prefix:"https://twitter.com/",type:"input",length:256,anchor:true},
  {key:"telegram",name:"telegram account",prefix:"https://t.me/",type:"input",length:256,anchor:true}
]

const allTexts = () => texts;
const textKeys = () => texts.map(i=>i.key);
const textData = (key) => texts.find(i=>i.key === key) || texts[0];
const textPrefix = (key) => texts.find(i=>i.key === key).prefix || texts[0].prefix;

/******************************************************************/

/***********************************|
|         TNS Registrars            |
|__________________________________*/

const registrars = {
  "trx":{
    address: "TCvLCFCxhWTrt372A6sH2sMTPcLx8KjQJM",
    fees: [5e10,2e10,4e9,5e8,1e8],
    rent:[634195,253678,63419,7927,3170],
  },
  "tron":{
    address: "TRoi8QxtY8GE6hjvVqp7TZuyXjaKefM2sv",
    fees: [5e10,2e10,4e9,5e8,1e8],
    rent:[634195,253678,63419,7927,3170],
  },
  "usdd":{
    address: "TK4ZbyLcwnSgsE2gmGFFt5ikwJz5bDbjcN",
    fees: [5e10,2e10,4e9,5e8,1e8],
    rent:[634195,253678,63419,7927,3170],
  },
  "tns":{
    address: "THdRqZMhHd1xrGvceJB69ewyFjAV8Urxyz",
    fees: [5e10,2e10,4e9,5e8,1e8],
    rent:[634195,253678,63419,7927,3170],
  },
}
const getTld = (domain) => {
  if(!domain) return "trx";
  let _labels = domain.split(".");
  let _tld = _labels[_labels.length-1];
  if(!registrars[_tld]) return "trx";
  return _tld;
}
const isTld = (tld) => !!registrars[tld].address;
const allRegistrar = () =>  Object.keys(registrars);
const getRegistrar = (tld = "trx") => registrars[tld] || registrars["trx"];
const registrar = (tld = "trx") => registrars[tld].address || registrars["trx"].address;
const fees = (tld = "trx",chars = 5) => {
  let _fees = (registrars[tld] || registrars["trx"]).fees;
  chars = chars <= _fees.length?chars:_fees.length;
  return _fees[chars-1]
}
const rent = (tld = "trx",chars = 5) => {
  let _rent = (registrars[tld] || registrars["trx"]).rent;
  chars = chars <= _rent.length?chars:_rent.length;
  return _rent[chars-1]
}

/******************************************************************/

const Utils = {
  Zero_address: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
  Zero_address_hex: "410000000000000000000000000000000000000000",
  registry : "TQjytiTrkyQnkxt3Xcz2ii9Yy8xvRUVZRF",
  resolver : "TGYZgGL1hxeDYqXXupE89NySmGfPJH4kdK",
  registrar : "THdRqZMhHd1xrGvceJB69ewyFjAV8Urxyz",

  setToastAlert,getToastAlert,

  isZeroAddress,


  viewUser,setUser,getUser,

  chainIds,chainSymbol,chainData,chainId,chainName,
  allTexts,textKeys,textData,textPrefix,
  getTld,isTld,allRegistrar,getRegistrar,registrar,fees,rent,

  wait,
  shortAddress,shortName,

  floor,ceil,pretty,largepretty,

  getTimeInGMT,

  validateName,validateUserame,validateDescription,validateSocial,



}


export default Utils
